import React from "react";
import { createRoot } from "react-dom/client";

const VTable = React.lazy(() => import("./components/vtable/VTable"));
const Ansprechpartner = React.lazy(() => import("./components/ansprechpartner/index"));

const table = document.getElementById("vtable");
const ansprechpartner = document.getElementById("ansprechpartnersuche");

if (table !== null) {
    const t = createRoot(table);
    t.render(
        <>
            <VTable />
        </>
    );
}

if (ansprechpartner !== null) {
    const ap = createRoot(ansprechpartner);
    ap.render(
        <>
            <Ansprechpartner/>
        </>
    );
}
